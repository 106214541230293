// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-designs-aanzee-js": () => import("./../../src/pages/designs/aanzee.js" /* webpackChunkName: "component---src-pages-designs-aanzee-js" */),
  "component---src-pages-designs-angel-js": () => import("./../../src/pages/designs/angel.js" /* webpackChunkName: "component---src-pages-designs-angel-js" */),
  "component---src-pages-designs-cypher-js": () => import("./../../src/pages/designs/cypher.js" /* webpackChunkName: "component---src-pages-designs-cypher-js" */),
  "component---src-pages-designs-js": () => import("./../../src/pages/designs.js" /* webpackChunkName: "component---src-pages-designs-js" */),
  "component---src-pages-designs-prophet-js": () => import("./../../src/pages/designs/prophet.js" /* webpackChunkName: "component---src-pages-designs-prophet-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

